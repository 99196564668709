#pages-wrapper {
    display: flex;
    gap: 15px;
    margin: 15px;
}

.brise-theme-font {
    background: linear-gradient(120.03deg, #15D0B8 6.72%, #3910DE 88.76%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: none;
    cursor: pointer;
}

#logo-page-container {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(110.63deg, #193670 10.43%, #171959 65.49%);
    display: flex;
    justify-content: center;
    align-items: center;
}

#logo-page-container .circle-container {
    width: 100%;
    height: 100vh;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    overflow: hidden;
}

#logo-page-container .circle {
    background: linear-gradient(110.63deg, #193670 10.43%, #171959 65.49%);
    box-shadow: 0px 34.7482px 44.16px rgb(1 1 1 / 7%);
    border-radius: 100%;
}

#logo-page-container div#logo-page-content {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    gap: 10px;
}

#logo-page-container h2 {
    letter-spacing: 5px;
    color: white;
    font-size: 6rem;
    font-weight: 400;
    position: relative;
    bottom: 5px;
}

.capital {
    text-transform: capitalize;
}

div.dropdown-options-wrapper {
    position: absolute;
    width: 100%;
    left: 0;
    top: calc(100% + 5px);
    z-index: 1;
}

div.dropdown-options {
    padding: 15px;
    display: flex;
    flex-flow: column nowrap;
    gap: 12px;
    background: #EFF3F9;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    text-align: start;
}

div.dropdown-options>div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.popup-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-container {
    box-shadow: 0 0 10px -8px;
    background: #EFF3F9;
    border-radius: 10px;
    overflow: hidden;
}

.popup-container>* {
    padding: 6px 40px 15px;
}

.popup-container>.popup-head {
    font-size: 18px;
    font-weight: 900;
    border-bottom: 1px solid #FFF;
}

.popup-container>.popup-head.white {
    background: white;
    border: none;
}

.popup-container>.popup-btns {
    display: flex;
    gap: 25px;
    justify-content: center;
}

.popup-container>.popup-btns>* {
    width: 50%;
    padding: 7px 16px;
    font-size: 16px;
    border-radius: 5px;
    box-shadow: 0px 24px 32px 0px #32324714,
        0px 16px 16px 0px #32324714,
        0px 4px 4px 0px #00000040;
}

.popup-container>.popup-content {
    display: flex;
    flex-flow: column nowrap;
    gap: 15px;
}

#switch-wrapper {
    width: 40px;
    height: 20px;
    border-radius: 15px;
    background: #ccced1;
    display: flex;
    align-items: center;
}

#switch-circle {
    aspect-ratio: 1;
    width: 16px;
    border-radius: 100%;
    background: white;
    margin: 2px;
}