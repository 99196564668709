.grid-container {
    display: grid;
    grid-template-columns: .5fr 5fr;
}

.dashboard {
    background: #EFF3F9;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-flow: column nowrap;
    gap: 15px;
}

.dashboard #dashboard-content-container {
    display: grid;
    gap: 15px;
    grid-template-columns: 3fr 1fr;
}

.dashboard #dashboard-content-container>div {
    display: flex;
    flex-flow: column nowrap;
    gap: 15px;
}

#transactions {
    background: #EFF3F9;
    border-radius: 10px;
    padding: 15px;
    display: grid;
    grid-auto-rows: .5fr 2fr;
    gap: 15px;
}

#settlements {
    background: #EFF3F9;
    border-radius: 10px;
    padding: 15px;
    display: grid;
    grid-auto-rows: .5fr .01fr 3fr;
    gap: 15px;
}

#contract,
#documents-verification {
    background: #FFFFFF;
    border-radius: 10px;
    min-height: 75%;
}

#api,
#settings {
    background: #EFF3F9;
    border-radius: 10px;
    padding: 15px;
}

#settings {
    position: relative;
    overflow: hidden;
}

#your-business {
    background: white;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

#your-business #head {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
}

#your-business #head>div {
    display: flex;
    flex-flow: row nowrap;
    gap: 15px;
}

.dropdown {
    display: flex;
    align-items: center;
    background: #EFF3F9;
    border-radius: 10px;
    padding: 10px;
    width: fit-content;
    position: relative;
    cursor: pointer;
}

.dropdown>.dropdown-select {
    font-weight: 700;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 0 15px 0 10px;
    justify-content: space-between;
    min-width: 150px;
}

#calender {
    padding: 0 10px 0 15px;
    border-left: 1px solid white;
    display: flex;
}

#your-business #archieve {
    background: #EFF3F9;
    border-radius: 10px;
    padding: 8px 12px;
    display: flex;
    justify-content: center;
}

.head-cards-container {
    position: relative;
    display: flex;
    gap: 10px;
}

.head-card {
    background: linear-gradient(110.63deg, #193670 10.43%, #171959 65.49%);
    border-radius: 10px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    color: white;
    flex: auto;
    padding: 12px;
}

.head-card>p {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 15px;
    font-weight: 400;
    color: white;
}

.head-card>p>div {
    background: white;
    border-radius: 100%;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-type-1 .card-value-wrapper {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
}

.card-type-2 .card-value-wrapper {
    display: flex;
}


.card-type-1 .head-card {
    gap: 32px;
}

.card-type-2 .head-card {
    gap: 10px;
}

.card-type-2 .card-value-wrapper>div {
    padding: 0 6px;
    width: 50%;
    display: flex;
    flex-direction: column;
}

.card-type-2 .card-value-wrapper>div:first-child {
    border-right: 1px solid white;
}

.card-type-2 .card-value-wrapper p {
    padding: 12px 0 8px;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
}

.card-type-2 .card-value-wrapper small {
    display: block;
    font-size: 12px;
    text-align: center;
}

#table {
    background: white;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.line {
    width: 100%;
    position: relative;
    border-top: 0.92px solid #EFF3F9;
    width: calc(100% + 30px);
    left: -15px;
}

.line.l1 {
    top: 70px;
}

.line.l2 {
    top: 10px;
}

#table #top {
    width: 85%;
    display: flex;
    justify-content: space-between;
}

#table #top>* {
    width: 20%;
}

#table table {
    width: 100%;
}

#table table thead {
    border: 1px solid black;
}

#table table th {
    height: 75px;
    font-weight: 500;
    font-size: 13px;
    text-transform: capitalize;
}

#table table td {
    height: 60px;
    font-weight: 600;
    font-size: 13px;
}


#table table th,
#table table td {
    text-align: center;
}

#table table th:nth-child(1),
#table table tr>td:nth-child(1) {
    text-align: start;
}

#table table tr>td:nth-child(2) {
    text-decoration: underline;
}

/* #table table th:last-child,
#table table tr>td:last-child {
    text-align: end;
} */

.table-btn button {
    border-radius: 75px !important;
    color: white;
    text-align: center;
    outline: none;
    border: none;
    text-transform: uppercase;
    padding: 10px 0;
    width: 120px;
}

#table table tr>td.table-btn.settle-now button,
#table table tr>td.table-btn.default-theme button {
    background: linear-gradient(120.03deg, #15D0B8 6.72%, #3910DE 88.76%);
}

.successful button,
.settled button,
.active button,
.success button,
.verified button {
    background: #00A939;
}

.table-btn.expired button {
    background: #F6B048;
}

.table-btn.fail button {
    background: #F64848;
}

.table-btn.pending button {
    background: #BBBCCB;
}

#table #options {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

#table #options div#searchbar-container {
    display: flex;
    align-items: center;
}

#table #options #searchIcon {
    height: fit-content;
    margin-left: -35px;
    width: 35px;
}

.input-default-theme {
    background: #EFF3F9;
    border-radius: 6px;
    padding: 12px;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    line-height: 21px;
    letter-spacing: 0em;
    color: black;
    width: 20rem;
}

input.input-default-theme::placeholder {
    color: black;
}

#paginatoin-wrapper {
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
}

#paginatoin-wrapper>div {
    display: flex;
    gap: 25px;
}

.page-actions {
    background: #171959;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#offer {
    background: linear-gradient(110.63deg, #193670 10.43%, #171959 65.49%);
    border-radius: 10px;
    color: white;
    padding: 20px 10px;
}

#offer #reasons {
    margin: 20px 0;
}

#offer #reasons>div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#offer #big-image img {
    width: 80%;
    margin: 0 auto 0 40px;
}

#offer #buttons {
    display: flex;
    justify-content: space-around;
}

#offer #buttons * {
    background: linear-gradient(120.03deg, #15D0B8 6.72%, #3910DE 88.76%);
    box-shadow: 0px 34.7482px 44.16px rgba(1, 1, 1, 0.07), 0px 16.56px 11.04px rgba(1, 1, 1, 0.06), 0px 5.52px 5.52px rgba(1, 1, 1, 0.05), 0px 5.52px 4.416px rgba(1, 1, 1, 0.04), 0px 2.208px 2.76px rgba(1, 1, 1, 0.03), 0px 0.992806px 1.104px rgba(1, 1, 1, 0.02);
    border-radius: 10px;
    border-radius: 10px;
    outline: none;
    border: none;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 15px;
    text-transform: uppercase;
    color: white;
    width: fit-content;
    gap: 10px;
}

.pie-chart {
    background: white;
    padding: 15px 10px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    aspect-ratio: 1;
    gap: 15px;
    border-radius: 10px;
}

.pie-chart h2 {
    font-size: 19px;
}

.pie-chart-wrapper {
    width: 260px;
}

.right-actions-container {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
}

#linear-options::-webkit-scrollbar {
    display: none;
}

.linear-options {
    display: flex;
    gap: 25px;
    margin: 10px 0;
    overflow: auto;
}

.linear-options>button {
    padding: 14px 28px;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    font-style: normal;
    border-radius: 10px;
    border: none;
    outline: none;
    background: #FFFFFF;
    color: black;
    box-shadow: 0 0 10px -2px #b6b6b65d;
}

.linear-options.settings>button {
    padding: 6px 16px;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    border-radius: 10px;
    border: none;
    outline: none;
    background: #FFFFFF;
    color: black;
    box-shadow: 0 0 10px -2px #b6b6b641;
}

.linear-options>button.selected {
    background: linear-gradient(110.63deg, #193670 10.43%, #171959 65.49%);
    color: #FFFFFF;
}

.heading-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    margin: 3px 0 18px 0;
}

.heading-row span {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
}

.default-theme-btn {
    padding: 10px 25px;
    background: linear-gradient(120.03deg, #15D0B8 6.72%, #3910DE 88.76%);
    border-radius: 8px;
    font-weight: 600;
    letter-spacing: 0em;
    border: none;
    outline: none;
    color: #FFFFFF;
}

.default-theme-btn.upper {
    text-transform: uppercase;
}

#setting-options {
    margin: 20px 0;
    white-space: nowrap;
}

#setting-options h2 {
    margin: 15px;
    font-size: 18px;
}

#business-details {
    display: grid;
    grid-template-columns: 1fr 2.5fr;
    gap: 15px;
}

#business-details>div {
    display: flex;
    gap: 25px;
    justify-content: center;
    flex-flow: column nowrap;
    height: fit-content;
}

#business-details #profile {
    align-items: center;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 25px 40px;
}

#business-details #profile-detail {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
}

#business-details #profile-detail>span:first-child {
    font-size: 18px;
    font-weight: 800;
}

#business-details #profile-detail>span:last-child {
    font-size: 14px;
}

#business-details .details {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 25px 40px;
}

#detail-data {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

#detail-data>div {
    width: 100%;
    display: flex;
}

#detail-data #key {
    width: 40%;
}

#detail-data #value {
    width: 60%;
    font-weight: 600;
}

#transaction-filters {
    position: absolute;
    right: 0;
    top: calc(100% + 15px);
    z-index: 1;
    background: #eff3f9;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 300px;
    border-radius: 10px;
    box-shadow: 0 0 10px -8px;
    overflow: hidden;
    margin-bottom: 20px;
}

#transaction-filters>h2 {
    font-size: 16px;
}

#transaction-filters>#head {
    background: white;
    padding: 20px;
}

#transaction-filters>* {
    padding: 0 20px;
}

#transaction-filters>div {
    display: flex;
    flex-flow: column nowrap;
    gap: 6px;
}

#transaction-filters>div>div {
    display: flex;
    gap: 10px;
}

#api-key-details {
    border: 1px solid #171959;
    display: flex;
    flex-flow: column;
    gap: 10px;
    font-size: 16px;
    margin-bottom: 15px;
    padding: 0.75rem;
    border-radius: 8px;
    border: 1px solid #BBBCCB;
    background: #fafcff;
    box-shadow:
        0px 0.9928057193756104px 1.1039999723434448px 0px #01010105,
        0px 2.2079999446868896px 2.759999990463257px 0px #01010108,
        0px 5.519999980926514px 4.415999889373779px 0px #0101010A,
        0px 5.519999980926514px 5.519999980926514px 0px #0101010D,
        0px 16.559999465942383px 11.039999961853027px 0px #0101010F,
        0px 34.748199462890625px 44.15999984741211px 0px #01010112;
    outline: none;
    color: black;
}

#upload-contract #file-upload {
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
    margin: 20px 0;
    border: 1px solid #17195947;
}

#upload-contract #file-detail {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

#upload-contract #file-detail>div {
    display: flex;
    align-items: center;
    gap: 10px;
}

#upload-contract .dropdown {
    background: white;
    width: calc(100% - 2rem);
    margin: 10px 0;
    border: 1px solid #17195947;
}

#upload-contract .dropdown>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.change-password-wrapper {
    padding: 15px;
    border-radius: 10px;
    background: white;
    margin-left: 10px;
}

.change-password-wrapper>h2 {
    font-size: 30px;
    font-weight: 700;
    line-height: 25px;
}

.change-password-wrapper>button {
    margin-top: 30px;
    padding: 5px 30px;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
}

.change-password-wrapper>small {
    display: block;
    margin: 15px 0 40px;
}

.change-password-wrapper.blur-inputs>input {
    opacity: .5;
    padding: .75rem;
    border-radius: 10px;
}

.change-password-wrapper.blur-inputs>input:focus {
    opacity: 1;
}

#change-avatar {
    padding: 15px;
    border-radius: 10px;
    background: white;
    margin-left: 10px;
}

#change-avatar #profile-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 20px 30px 0;
}

#change-avatar #profile-wrapper button {
    height: fit-content;
    padding: 10px 35px;
}

#change-avatar #profile {
    display: flex;
    align-items: center;
    gap: 50px;
}

#change-avatar #profile-wrapper button {
    height: fit-content;
    padding: 10px 35px;
}

#avatars {
    margin: 50px 0;
    display: flex;
    flex-flow: column nowrap;
    gap: 15px;
}

#avatars>div {
    display: flex;
    justify-content: space-evenly;
}

#no-data-image-wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 60px;
}

#no-data-image-wrapper>h2 {
    font-size: 20px;
    margin-top: 25px;
}

#no-data-image-wrapper>h2,
#no-data-image-wrapper>small {
    width: fit-content;
}

#two-column-inputs>div {
    display: flex;
    justify-content: space-between;
}

#merchant-transactions #chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    padding: 15px;
    border-radius: 10px;
}

.total-payment-recieved #head {
    background: #FFFFFF;
    border-radius: 10px;
}

.total-payment-recieved #chart-container {
    gap: 20px;
    display: flex;
}

#chart-brise-scale {
    gap: 20px;
    display: flex;
    opacity: .70;
    font-size: 14px;
}

#chart-brise-scale>div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

#chart-brise-scale>small {
    rotate: 90deg;
    height: fit-content;
    width: fit-content;
    margin: auto;
}

.color-circle {
    width: 20px;
    height: 20px;
    border-radius: 100%;
}

.color-circle.green {
    background: #00A939;
}

.color-circle.grey {
    background: #BBBCCB;
}

.color-circle.red {
    background: #F64848;
}

#merchant-transactions #list>div {
    display: flex;
    gap: 10px;
}

.total-payment-recieved #list>div {
    flex-flow: column;
}

#merchant-transactions #list>div>div {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

#merchant-transactions #list>div>b {
    font-size: 24px;
    text-align: right;
}

#merchant-transactions #list>div span {
    display: flex;
    justify-content: flex-end;
    width: 95px;
}

#merchant-transactions #list {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    gap: 40px;
}

#merchant-transactions #chart {
    width: 250px;
}